<template>
	<w-layout column>
		<w-flex shrink>
			<w-layout row>
				<w-flex mr-1 xs6>
					<w-date-picker
						:allowed-dates="allowedFirstDate"
						class="mr-1"
						:clearable="isFirstDateClearable"
						:color-field="inputColor"
						:disabled="isFirstDateDisabled"
						:error="errorMessages.length > 0"
						:label="displayedLabels[0]"
						:max="max"
						:min="min"
						:open-on-focus="openFirstDateOnFocus"
						:readonly="isFirstDateReadonly"
						:required="isFirstDateRequired"
						:shrink="shrink"
						:small="small"
						:value="firstDate"
						@input="onFirstDateUpdated($event)"
					/>
				</w-flex>
				<w-flex ml-1 xs6>
					<w-date-picker
						ref="second-date-picker"
						:allowed-dates="allowedSecondDate"
						class="ml-1"
						:clearable="isSecondDateClearable"
						:color-field="inputColor"
						:disabled="isSecondDateDisabled"
						:error="errorMessages.length > 0"
						:label="displayedLabels[1]"
						:max="max"
						:min="min"
						:open-on-focus="openSecondDateOnFocus"
						:readonly="isSecondDateReadonly"
						:required="isSecondDateRequired"
						:shrink="shrink"
						:small="small"
						:value="secondDate"
						@input="onSecondDateUpdated($event)"
					/>
				</w-flex>
			</w-layout>
		</w-flex>
		<div v-if="errorMessages.length > 0" class="v-text-field__details" style="margin-top: -15px">
			<div class="v-messages theme--dark error--text">
				<div class="v-messages__wrapper">
					<div class="v-messages__messages" v-text="errorMessages[0]" />
				</div>
			</div>
		</div>
	</w-layout>
</template>

<script>
/**
 * @displayName w-date-range-picker
 *
 * @since 0.3.2
 */

export default {
	name: 'WDateRangePicker',
	props: {
		allowedDates: {
			default: undefined,
			required: false,
			type: [Array, Function],
			validator: value => (Array.isArray(value) ? value.length == 2 && value.every(item => typeof item == 'function') : typeof item == 'function')
		},
		clearable: {
			default: true,
			required: false,
			type: [Array, Boolean],
			validator: value => (Array.isArray(value) ? value.length === 2 && value.every(item => typeof item == 'boolean') : typeof value == 'boolean')
		},
		disabled: {
			default: false,
			required: false,
			type: [Array, Boolean],
			validator: value => (Array.isArray(value) ? value.length === 2 && value.every(item => typeof item == 'boolean') : typeof value == 'boolean')
		},
		errorMessages: {
			required: false,
			type: Array,
			default: () => []
		},
		firstDate: {
			required: false,
			type: String,
			default: undefined
		},
		inputColor: {
			default: undefined,
			required: false,
			type: String
		},
		labels: {
			default: () => [],
			required: false,
			type: Array,
			validator: value => value.length === 0 || value.length === 2
		},
		max: {
			default: undefined,
			required: false,
			type: String,
			validator: value => new Date(value).toString() !== 'Invalid Date'
		},
		min: {
			default: undefined,
			required: false,
			type: String,
			validator: value => new Date(value).toString() !== 'Invalid Date'
		},
		openOnFocus: {
			default: false,
			required: false,
			type: [Array, Boolean],
			validator: value => (Array.isArray(value) ? value.length === 2 && value.every(item => typeof item === 'boolean') : true)
		},
		secondDate: {
			required: false,
			type: String,
			default: undefined
		},
		shrink: {
			required: false,
			type: Boolean,
			default: false
		},
		readonly: {
			default: false,
			required: false,
			type: [Array, Boolean],
			validator: value => (Array.isArray(value) ? value.length === 2 && value.every(item => typeof item == 'boolean') : typeof value == 'boolean')
		},
		required: {
			default: false,
			required: false,
			type: [Array, Boolean],
			validator: value => (Array.isArray(value) ? value.length === 2 && value.every(item => typeof item == 'boolean') : typeof value == 'boolean')
		},
		small: {
			default: false,
			required: false,
			type: Boolean
		}
	},
	computed: {
		displayedLabels: function () {
			if (Array.isArray(this.labels) && this.labels.length === 2) {
				return this.labels
			}
			return [this.$t('start_date'), this.$t('end_date')]
		},
		isFirstDateClearable: function () {
			if (!Array.isArray(this.clearable)) {
				return this.clearable
			}
			return this.clearable[0]
		},
		isFirstDateDisabled: function () {
			if (!Array.isArray(this.disabled)) {
				return this.disabled
			}
			return this.disabled[0]
		},
		isFirstDateReadonly: function () {
			if (!Array.isArray(this.readonly)) {
				return this.readonly
			}
			return this.readonly[0]
		},
		isFirstDateRequired: function () {
			if (!Array.isArray(this.required)) {
				return this.required
			}
			return this.required[0]
		},
		isSecondDateClearable: function () {
			if (!Array.isArray(this.clearable)) {
				return this.clearable
			}
			return this.clearable[1]
		},
		isSecondDateDisabled: function () {
			if (!Array.isArray(this.disabled)) {
				return this.disabled
			}
			return this.disabled[1]
		},
		isSecondDateReadonly: function () {
			if (!Array.isArray(this.readonly)) {
				return this.readonly
			}
			return this.readonly[1]
		},
		isSecondDateRequired: function () {
			if (!Array.isArray(this.required)) {
				return this.required
			}
			return this.required[1]
		},
		openFirstDateOnFocus: function () {
			if (typeof this.openOnFocus === 'boolean') {
				return this.openOnFocus
			}
			return this.openOnFocus[0]
		},
		openSecondDateOnFocus: function () {
			if (typeof this.openOnFocus === 'boolean') {
				return this.openOnFocus
			}

			return this.openOnFocus[1]
		}
	},
	methods: {
		allowedFirstDate: function (value) {
			if ((typeof this.allowedDates == 'function' && !this.allowedDates(value)) || (Array.isArray(this.allowedDates) && !this.allowedDates[0](value))) {
				return false
			}
			if (!this.secondDate) {
				return true
			}
			const date = new Date(value)
			const secondDate = new Date(this.secondDate)

			return date.getTime() < secondDate.getTime()
		},
		allowedSecondDate: function (value) {
			if ((typeof this.allowedDates == 'function' && !this.allowedDates(value)) || (Array.isArray(this.allowedDates) && !this.allowedDates[1](value))) {
				return false
			}
			if (!this.firstDate) {
				return true
			}
			const date = new Date(value)
			const firstDate = new Date(this.firstDate)

			return date.getTime() > firstDate.getTime()
		},
		onFirstDateUpdated: function (newValue) {
			this.$emit('update:first-date', newValue)

			if (newValue && !this.secondDate) {
				const secondDatePicker = this.$refs['second-date-picker']
				if (!secondDatePicker || !newValue) {
					return
				}
				secondDatePicker.menu = true
			}
		},
		onSecondDateUpdated: function (newValue) {
			this.$emit('update:second-date', newValue)
		}
	}
}
</script>
